// src/components/ReservationModal/ReservationModal.jsx

import React, { useState, useEffect } from 'react';
import {
  Modal,
  Box,
  Typography,
  IconButton,
  CircularProgress,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ReservationStepper from './ReservationStepper';
import ReservationDetails from './ReservationDetails';
import ReservationEditForm from './ReservationEditForm';
import { getReservationsByRoom } from '../../api/reservations';
import dayjs from 'dayjs';

const ReservationModal = ({
  user,
  open,
  onClose,
  onSave,
  onCancel,
  rooms,
  initialReservation,
}) => {
  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [reservation, setReservation] = useState(initialReservation || {});
  const [disabledDates, setDisabledDates] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(dayjs());

  const isNewReservation = !initialReservation && !isEditing;

  useEffect(() => {
    const fetchDisabledDates = async () => {
      if (reservation.room && user?.uid) {
        const startOfMonth = currentMonth.startOf('month').toDate();
        const endOfMonth = currentMonth.endOf('month').toDate();

        const unavailableDates = await getReservationsByRoom(
          user?.uid,
          reservation.room,
          startOfMonth,
          endOfMonth
        );

        const dates = unavailableDates.map(({ start, end }) => ({
          start: dayjs(start),
          end: dayjs(end),
        }));
        setDisabledDates(dates);
      }
    };
    fetchDisabledDates();
  }, [reservation?.room, user?.uid, currentMonth]);

  const handleCloseModal = () => {
    setIsEditing(false);
    setReservation(initialReservation || {});
    onClose();
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    setReservation(initialReservation);
  };

  const handleSave = async (updatedReservation) => {
    setLoading(true);
    try {
      await onSave(updatedReservation);
      handleCloseModal();
    } catch (error) {
      console.error('Error al guardar la reserva:', error);
      // Aquí podrías añadir feedback al usuario
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal open={open} onClose={handleCloseModal}>
      <Box
        sx={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { xs: '90%', sm: '600px' },
          maxHeight: '90vh',
          bgcolor: 'background.paper',
          borderRadius: '12px',
          boxShadow: 24,
          p: 4,
          overflowY: 'auto',
        }}
      >
        <IconButton
          sx={{ position: 'absolute', top: 8, right: 8 }}
          onClick={handleCloseModal}
        >
          <CloseIcon />
        </IconButton>

        <Typography variant="h5" align="center" gutterBottom>
          {isNewReservation
            ? 'Realizar Reserva'
            : isEditing
            ? 'Editar Reserva'
            : 'Detalles de la Reserva'}
        </Typography>

        {loading && (
          <CircularProgress
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              mt: '-12px',
              ml: '-12px',
            }}
          />
        )}

        {!loading && (
          <>
            {isNewReservation && (
              <ReservationStepper
                user={user}
                onSave={handleSave}
                onClose={handleCloseModal}
                rooms={rooms}
                getDisabledDates={getReservationsByRoom}
                currentMonth={currentMonth}
                setCurrentMonth={setCurrentMonth}
              />
            )}

            {!isNewReservation && !isEditing && (
              <ReservationDetails
                reservation={reservation}
                rooms={rooms}
                onClose={handleCloseModal}
                onEdit={handleEditClick}
                onCancel={onCancel}
              />
            )}

            {isEditing && (
              <ReservationEditForm
                reservation={reservation}
                rooms={rooms}
                onSave={handleSave}
                onCancelEdit={handleCancelEdit}
                disabledDates={disabledDates}
                currentMonth={currentMonth}
                setCurrentMonth={setCurrentMonth}
              />
            )}
          </>
        )}
      </Box>
    </Modal>
  );
};

export default ReservationModal;