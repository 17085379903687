// src/components/Timeline/TimelineComponent.jsx

/* eslint-disable import/no-unresolved */
import React, { useState, useEffect, useRef } from 'react';
import Timeline from 'react-timelines';
import { buildTimebar, buildTrack } from './builders';
import 'react-timelines/lib/css/style.css';
import { fill } from './utils';
import ReservationModal from '../ReservationModal/ReservationModal';
import { saveReservation } from '../../api/reservations';
import { useAuth } from '../../hooks/useAuth';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

const now = new Date();

// Habitaciones
let tracksById = {};

const NUM_OF_YEARS = 2;
const START_YEAR = now.getFullYear();

const timebar = buildTimebar(START_YEAR, NUM_OF_YEARS);

const MIN_ZOOM = 2;
const MAX_ZOOM = 200;

const TimelineComponent = (props) => {
  const [tracks, setTracks] = useState([]);
  const { reservations, rooms } = props;
  const [zoom, setZoom] = useState(200);
  const { currentUser } = useAuth();

  const timelineRef = useRef(null); // Referencia al contenedor del timeline

  // Estado para el modal de reserva
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedReservation, setSelectedReservation] = useState(null); // Reserva seleccionada

  useEffect(() => {
    if (rooms?.length > 0) {
      let fullRooms = rooms.concat(
        { id: 1, title: ' 1' },
        { id: 2, title: ' 1' },
        { id: 3, title: ' 1' },
        { id: 4, title: ' 1' },
        { id: 5, title: ' 1' },
        { id: 6, title: ' 1' },
        { id: 7, title: ' 1' },
        { id: 8, title: ' 1' },
        { id: 9, title: ' 1' },
        { id: 10, title: ' 1' }
      );

      tracksById = fill(fullRooms.length).reduce((acc, i) => {
        const track = buildTrack(fullRooms[i], i, reservations);
        acc[track.id] = track;
        return acc;
      }, {});

      setTracks(Object.values(tracksById).reverse());
    }
  }, [rooms, reservations]);

  const handleCancelReservation = async (reservationData) => {
    // Lógica para cancelar la reserva
    console.log('Cancelando reserva:', reservationData);
    // Implementa la lógica de cancelación aquí
  };

  const handleZoomIn = () => {
    setZoom((prevZoom) => Math.min(prevZoom + 10, MAX_ZOOM));
  };

  const handleZoomOut = () => {
    setZoom((prevZoom) => Math.max(prevZoom - 10, MIN_ZOOM));
  };

  const handleToggleTrackOpen = (track) => {
    setTracks((prevTracks) => {
      const updatedTracks = {
        ...tracksById,
        [track.id]: {
          ...track,
          isOpen: !track.isOpen,
        },
      };
      return Object.values(updatedTracks);
    });
  };

  const start = new Date(`${START_YEAR}`);
  const end = new Date(`${START_YEAR + NUM_OF_YEARS}`);

  const handleOnSaveReservation = (newReservation) => {
    // Lógica para guardar la reserva
    console.log('Guardando reserva:', newReservation);
    saveReservation({
      user: currentUser,
      reservation: newReservation,
    });
    // Aquí podrías actualizar el estado de reservas o recargar los datos
  };

  // Manejar el desplazamiento del timeline
  const handleScroll = () => {
    const scrollPosition = timelineRef.current.scrollLeft; // Obtiene la posición de desplazamiento
    console.log(`Scroll position: ${scrollPosition}px`); // Registra la posición de desplazamiento
  };

  // Manejar el clic en una reserva
  const handleReservationClick = (reservationData) => {
    const { id } = reservationData;
    let reservationSelected = reservations.find((reservation) => reservation.id === id);
    setSelectedReservation(reservationSelected); // Cargar la reserva seleccionada
    setModalOpen(true); // Abrir el modal en modo detalles
  };

  // Manejar el clic en la línea de tiempo para crear una nueva reserva
  const handleTimelineClick = (e) => {
    // Verifica si el clic se realizó en un área vacía
    // Dependiendo de la implementación de 'react-timelines', puede que necesites ajustar esto
    // Por simplicidad, usaremos el evento onDoubleClick para abrir el modal
    // Puedes cambiarlo a onClick si prefieres
  };

  const handleModalOpen = () => {
    setSelectedReservation(null); // No hay reserva seleccionada, se abrirá el stepper
    setModalOpen(true);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
      <div
        className="app"
        onDoubleClick={handleModalOpen} // Doble clic para crear una nueva reserva
        style={{ height: '100vh', overflow: 'hidden' }} // Asegúrate de que el contenedor ocupe el alto total
      >
        <div
          ref={timelineRef}
          style={{ overflowX: 'auto', whiteSpace: 'nowrap', height: '100%' }} // Asegúrate de que el contenedor del timeline sea desplazable
          onScroll={handleScroll} // Añadir evento de desplazamiento
        >
          <Timeline
            scale={{
              start,
              end,
              zoom,
              zoomMin: MIN_ZOOM,
              zoomMax: MAX_ZOOM,
            }}
            isOpen={true}
            toggleOpen={() => {}} // Implementa si es necesario
            zoomIn={handleZoomIn}
            zoomOut={handleZoomOut}
            clickElement={handleReservationClick} // Clic en una reserva
            clickTrackButton={(track) => {
              console.log(JSON.stringify(track));
            }}
            timebar={timebar}
            tracks={tracks}
            now={now}
            toggleTrackOpen={handleToggleTrackOpen}
            enableSticky={true}
            scrollToNow={true}
            useAsGrid={true}
          />
        </div>

        {/* Modal de reserva */}
        <ReservationModal
          user={currentUser}
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          onSave={handleOnSaveReservation}
          onCancel={handleCancelReservation}
          rooms={rooms}
          initialReservation={selectedReservation}
        />
      </div>
    </LocalizationProvider>
  );
};

export default TimelineComponent;