import {
  MONTH_NAMES,
  MONTHS_PER_YEAR,
  MAX_TRACK_START_GAP,
  MAX_ELEMENT_GAP,
} from "./constants";

import {
  fill,
  hexToRgb,
  colourIsLight,
  addMonthsToYearAsDate,
  nextColor,
} from "./utils";

// Construye las celdas para los meses
export const buildMonthCells = (startYear, numOfYears) => {
  const v = [];
  for (let i = 0; i < MONTHS_PER_YEAR * numOfYears; i += 1) {
    const startMonth = i;
    const start = addMonthsToYearAsDate(startYear, startMonth);
    const end = addMonthsToYearAsDate(startYear, startMonth + 1);
    v.push({
      id: `m${startMonth}`,
      title: MONTH_NAMES[startMonth % 12],
      start,
      end,
    });
  }
  return v;
};

// Construye las celdas para los días de cada mes
export const buildDayCells = (month, startYear) => {
  const v = [];
  const startDate = new Date(startYear, month, 1);
  const endDate = new Date(startYear, month + 1, 0); // Último día del mes

  for (let day = 1; day <= endDate.getDate(); day++) {
    const date = new Date(startYear, month, day);
    const dayInitial = date.toLocaleDateString("es-ES", { weekday: "short" })[0].toUpperCase(); // Inicial del día en español
    v.push({
      id: `${startDate.getFullYear()}-${month + 1}-${day}${Math.random()}`, // Cambia la clave aquí
      title: `${dayInitial}${day}`, 
      start: date,
      end: new Date(date.getFullYear(), date.getMonth(), day + 1), // Fin del día
    });
  }
  return v;
};

// Construye la barra de tiempo que incluirá meses y días

export const buildTimebar = (startYear, numOfYears) => {
  const monthCells = buildMonthCells(startYear, numOfYears);

  return [
    {
      id: "months",
      title: "Meses",
      cells: monthCells,
      useAsGrid: false,
      style: {},
    },
    {
      id: "days",
      title: "Días",
      cells: monthCells.flatMap((monthCell) => buildDayCells(monthCell.start.getMonth(),startYear)),
      useAsGrid: false,
      style: {},
    },
  ];
};

// Otras funciones de construcción permanecen igual
export const buildElement = ({ reservation, i }) => {
  const bgColor = nextColor();
  const color = colourIsLight(...hexToRgb(bgColor)) ? "#000000" : "#ffffff";
  const { id, guest, start, end } = reservation;
  return {
    id,
    title: guest,
    start,
    end,
    style: {
      backgroundColor: `#${bgColor}`,
      color,
      borderRadius: "12px",
      boxShadow: '-2px 6px 7px 0px rgba(0, 0, 0, 0.3)',
      textTransform: "capitalize",
    },
  };
};

export const buildTrackStartGap = () =>
  Math.floor(Math.random() * MAX_TRACK_START_GAP);
  
export const buildElementGap = () =>
  Math.floor(Math.random() * MAX_ELEMENT_GAP);

export const buildElements = (trackId, reservations) => {
  const v = [];
  let i = 1;

  reservations.forEach((reservation) => { 
 

    v.push(
      buildElement({
        reservation,
        i,
      })
    );
    i += 1;
  });

  return v;
};


export const buildTrack = (room, trackId, reservations) => {
  const tracks = fill(
    Math.floor(Math.random() * 2) + 1
  );

  let reservationsByRoom = reservations.filter((reservation) => reservation.room == room.id);

  return {
    id: room.id,
    title: room.name,
    elements: buildElements(trackId, reservationsByRoom),
    tracks,
    isOpen: false,
  };
};