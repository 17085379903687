// src/components/ReservationModal/ReservationDetails.jsx

import React from 'react';
import { Box, Typography, Button, Divider, Grid } from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PeopleIcon from '@mui/icons-material/People';
import CommentIcon from '@mui/icons-material/Comment';
import dayjs from 'dayjs';

const ReservationDetails = ({ reservation, rooms, onClose, onEdit, onCancel }) => {
  const room = rooms.find((room) => room.id === reservation.room);

  return (
    <>
      <Divider sx={{ mb: 2 }} />

      {/* Información de la Habitación */}
      <Box sx={{ mb: 2 }}>
        <Typography variant="h6">Habitación</Typography>
        <Typography variant="body1">{room?.name || 'No especificada'}</Typography>
      </Box>

      {/* Fechas y Horarios */}
      <Box sx={{ mb: 2 }}>
        <Typography variant="h6">Fechas y Horarios</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Box display="flex" alignItems="center">
              <CalendarTodayIcon sx={{ mr: 1 }} />
              <Typography variant="body1">
                Check-in: {dayjs(reservation.start).format('DD/MM/YYYY')}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <AccessTimeIcon sx={{ mr: 1 }} />
              <Typography variant="body1">
                Hora: {dayjs(reservation.start).format('HH:mm')}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box display="flex" alignItems="center">
              <CalendarTodayIcon sx={{ mr: 1 }} />
              <Typography variant="body1">
                Check-out: {dayjs(reservation.end).format('DD/MM/YYYY')}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <AccessTimeIcon sx={{ mr: 1 }} />
              <Typography variant="body1">
                Hora: {dayjs(reservation.end).format('HH:mm')}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* Detalles de la Reserva */}
      <Box sx={{ mb: 2 }}>
        <Typography variant="h6">Detalles</Typography>
        <Box display="flex" alignItems="center">
          <PeopleIcon sx={{ mr: 1 }} />
          <Typography variant="body1">Huéspedes: {reservation.guestsCount}</Typography>
        </Box>
        {reservation.comments && (
          <Box display="flex" alignItems="center" mt={1}>
            <CommentIcon sx={{ mr: 1 }} />
            <Typography variant="body1">{reservation.comments}</Typography>
          </Box>
        )}
      </Box>

      <Divider sx={{ mb: 2 }} />

      {/* Botones de Acción */}
      <Box display="flex" justifyContent="flex-end" gap={1}>
        <Button variant="outlined" color="secondary" onClick={onClose}>
          Cerrar
        </Button>
        <Button variant="contained" color="primary" onClick={onEdit}>
          Editar
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={() => onCancel(reservation)}
        >
          Cancelar Reserva
        </Button>
      </Box>
    </>
  );
};

export default ReservationDetails;