// src/components/ReservationModal/ReservationEditForm.jsx

import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, Divider, Grid, MenuItem } from '@mui/material';
import { DatePicker, MobileTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

const ReservationEditForm = ({
  reservation,
  rooms,
  onSave,
  onCancelEdit,
  disabledDates,
  currentMonth,
  setCurrentMonth,
}) => {
  const [updatedReservation, setUpdatedReservation] = useState(reservation);

  useEffect(() => {
    setUpdatedReservation(reservation);
  }, [reservation]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUpdatedReservation((prev) => ({ ...prev, [name]: value }));
  };

  const handleDateChange = (name, date) => {
    setUpdatedReservation((prev) => ({
      ...prev,
      [name]: date ? date.toISOString() : null,
    }));
  };

  const isRoomSelected = Boolean(updatedReservation.room);
  const isDatesSelected =
    updatedReservation.start &&
    updatedReservation.end &&
    updatedReservation.checkinTime &&
    updatedReservation.checkoutTime;

  const handleSaveClick = () => {
    onSave(updatedReservation);
  };

  return (
    <>
      <Divider sx={{ mb: 2 }} />

      {/* Selección de Habitación */}
      <Box sx={{ mb: 2 }}>
        <TextField
          select
          label="Habitación"
          name="room"
          fullWidth
          value={updatedReservation.room || ''}
          onChange={handleInputChange}
          helperText="Selecciona una habitación disponible."
          disabled // Deshabilitar si no deseas permitir cambiar la habitación
        >
          {rooms.map((room) => (
            <MenuItem key={room.id} value={room.id}>
              {room.name}
            </MenuItem>
          ))}
        </TextField>
      </Box>

      {/* Fechas y Horarios */}
      <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
        <DatePicker
          label="Fecha de Inicio"
          value={updatedReservation.start ? dayjs(updatedReservation.start) : null}
          onChange={(date) => handleDateChange('start', date)}
          shouldDisableDate={(date) => {
            return disabledDates.some(
              (disabledDate) =>
                date.isSame(disabledDate.start, 'day') ||
                (date.isAfter(disabledDate.start, 'day') && date.isBefore(disabledDate.end, 'day')) ||
                date.isSame(disabledDate.end, 'day')
            );
          }}
          onMonthChange={setCurrentMonth}
          slotProps={{ textField: { fullWidth: true } }}
          localeText={{ locale: 'es' }}
        />
        <MobileTimePicker
          label="Hora de Check-in"
          value={updatedReservation.checkinTime ? dayjs(updatedReservation.checkinTime) : null}
          onChange={(date) => handleDateChange('checkinTime', date)}
          slotProps={{ textField: { fullWidth: true } }}
          ampm={false}
          localeText={{ locale: 'es' }}
        />
      </Box>

      <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
        <DatePicker
          label="Fecha de Fin"
          value={updatedReservation.end ? dayjs(updatedReservation.end) : null}
          onChange={(date) => handleDateChange('end', date)}
          disablePast
          shouldDisableDate={(date) => {
            return disabledDates.some(
              (disabledDate) =>
                date.isSame(disabledDate.start, 'day') ||
                (date.isAfter(disabledDate.start, 'day') && date.isBefore(disabledDate.end, 'day')) ||
                date.isSame(disabledDate.end, 'day')
            );
          }}
          onMonthChange={setCurrentMonth}
          slotProps={{ textField: { fullWidth: true } }}
          localeText={{ locale: 'es' }}
        />
        <MobileTimePicker
          label="Hora de Check-out"
          value={updatedReservation.checkoutTime ? dayjs(updatedReservation.checkoutTime) : null}
          onChange={(date) => handleDateChange('checkoutTime', date)}
          slotProps={{ textField: { fullWidth: true } }}
          ampm={false}
          localeText={{ locale: 'es' }}
        />
      </Box>

      {/* Detalles de la Reserva */}
      <Box sx={{ mb: 2 }}>
        <TextField
          label="Cantidad de Huéspedes"
          type="number"
          name="guestsCount"
          value={updatedReservation.guestsCount || ''}
          onChange={handleInputChange}
          fullWidth
        />
      </Box>

      <Box sx={{ mb: 2 }}>
        <TextField
          label="Comentarios"
          name="comments"
          multiline
          rows={3}
          value={updatedReservation.comments || ''}
          onChange={handleInputChange}
          fullWidth
        />
      </Box>

      {/* Botones de Acción */}
      <Box display="flex" justifyContent="flex-end" gap={1}>
        <Button variant="outlined" onClick={onCancelEdit}>
          Cancelar
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSaveClick}
          disabled={!isRoomSelected || !isDatesSelected}
        >
          Guardar
        </Button>
      </Box>
    </>
  );
};

export default ReservationEditForm;