// src/components/ReservationModal/ReservationStepper.jsx

import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  TextField,
  MenuItem,
} from '@mui/material';
import { DatePicker, MobileTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { Timestamp } from 'firebase/firestore';

const ReservationStepper = ({
  user,
  onSave,
  onClose,
  rooms,
  getDisabledDates,
  currentMonth,
  setCurrentMonth,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [disabledDates, setDisabledDates] = useState([]);
  const [reservation, setReservation] = useState({
    room: '',
    start: null,
    end: null,
    checkinTime: null,
    checkoutTime: null,
    guestsCount: 1,
    comments: '',
  });

  const steps = ['Seleccionar Habitación', 'Seleccionar Fechas y Horarios', 'Detalles de la Reserva'];

  useEffect(() => {

    const fetchDisabledDates = async () => {
      if (reservation.room) {
        const startDate = currentMonth.startOf('month').toISOString();
        const endDate = currentMonth.endOf('month').toISOString();

        const unavailableDates = await getDisabledDates(
          user.uid,
          reservation.room,
          startDate,
          endDate
        );

        setDisabledDates(
          unavailableDates.map(({ start, end }) => ({
            start: dayjs(start),
            end: dayjs(end),
          }))
        );
      } else {
        setDisabledDates([]);
      }
    };

    fetchDisabledDates();
  }, [reservation.room, currentMonth, getDisabledDates, user]);

  const handleNext = () => {
    setActiveStep((prev) => prev + 1);
  };

  const handleBack = () => {
    setActiveStep((prev) => prev - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    setReservation({
      room: '',
      start: null,
      end: null,
      checkinTime: null,
      checkoutTime: null,
      guestsCount: 1,
      comments: '',
    });
    setDisabledDates([]);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setReservation((prev) => ({ ...prev, [name]: value }));
  };

  const handleDateChange = (name, date) => {
    date = Timestamp.fromDate(date);
    setReservation((prev) => ({ ...prev, [name]: date ? date.toISOString() : null }));
  };

  const isRoomSelected = Boolean(reservation.room);
  const isDatesSelected =
    reservation.start &&
    reservation.end &&
    reservation.checkinTime &&
    reservation.checkoutTime;

  const handleSaveClick = () => {
    onSave(reservation);
  };

  return (
    <Stepper activeStep={activeStep} orientation="vertical">
      {/* Paso 1: Selección de Habitación */}
      <Step>
        <StepLabel>Seleccionar Habitación</StepLabel>
        <StepContent>
          <TextField
            select
            label="Habitación"
            name="room"
            fullWidth
            value={reservation.room}
            onChange={handleInputChange}
            helperText="Selecciona una habitación disponible."
            sx={{ mb: 2 }}
          >
            {rooms.map((room) => (
              <MenuItem key={room.id} value={room.id}>
                {room.name}
              </MenuItem>
            ))}
          </TextField>
          <Box display="flex" justifyContent="flex-end">
            <Button variant="contained" onClick={handleNext} disabled={!isRoomSelected}>
              Siguiente
            </Button>
          </Box>
        </StepContent>
      </Step>

      {/* Paso 2: Selección de Fechas y Horarios */}
      <Step>
        <StepLabel>Seleccionar Fechas y Horarios</StepLabel>
        <StepContent>
          {/* Fecha de Inicio y Hora de Check-in */}
          <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
            <DatePicker
              label="Fecha de Inicio"
              value={reservation.start ? dayjs(reservation.start) : null}
              onChange={(date) => handleDateChange('start', date)}
              disablePast
              shouldDisableDate={(date) => {
                return disabledDates.some(
                  (disabledDate) =>
                    date.isSame(disabledDate.start, 'day') ||
                    (date.isAfter(disabledDate.start, 'day') && date.isBefore(disabledDate.end, 'day')) ||
                    date.isSame(disabledDate.end, 'day')
                );
              }}
              onMonthChange={setCurrentMonth}
              slotProps={{ textField: { fullWidth: true } }}
              localeText={{ locale: 'es' }}
            />
            <MobileTimePicker
              label="Hora de Check-in"
              value={reservation.checkinTime ? dayjs(reservation.checkinTime) : null}
              onChange={(date) => handleDateChange('checkinTime', date)}
              slotProps={{ textField: { fullWidth: true } }}
              ampm={false}
              localeText={{ locale: 'es' }}
            />
          </Box>

          {/* Fecha de Fin y Hora de Check-out */}
          <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
            <DatePicker
              label="Fecha de Fin"
              value={reservation.end ? dayjs(reservation.end) : null}
              onChange={(date) => handleDateChange('end', date)}
              disablePast
              shouldDisableDate={(date) => {
                return disabledDates.some(
                  (disabledDate) =>
                    date.isSame(disabledDate.start, 'day') ||
                    (date.isAfter(disabledDate.start, 'day') && date.isBefore(disabledDate.end, 'day')) ||
                    date.isSame(disabledDate.end, 'day')
                );
              }}
              onMonthChange={setCurrentMonth}
              slotProps={{ textField: { fullWidth: true } }}
              localeText={{ locale: 'es' }}
            />
            <MobileTimePicker
              label="Hora de Check-out"
              value={reservation.checkoutTime ? dayjs(reservation.checkoutTime) : null}
              onChange={(date) => handleDateChange('checkoutTime', date)}
              slotProps={{ textField: { fullWidth: true } }}
              ampm={false}
              localeText={{ locale: 'es' }}
            />
          </Box>

          <Box display="flex" justifyContent="space-between">
            <Button onClick={handleBack}>Atrás</Button>
            <Button
              variant="contained"
              onClick={handleNext}
              disabled={!isDatesSelected}
            >
              Siguiente
            </Button>
          </Box>
        </StepContent>
      </Step>

      {/* Paso 3: Detalles de la Reserva */}
      <Step>
        <StepLabel>Detalles de la Reserva</StepLabel>
        <StepContent>
          <TextField
            label="Cantidad de Huéspedes"
            type="number"
            name="guestsCount"
            value={reservation.guestsCount}
            onChange={handleInputChange}
            fullWidth
            sx={{ mb: 2 }}
          />

          <TextField
            label="Comentarios"
            name="comments"
            multiline
            rows={3}
            value={reservation.comments}
            onChange={handleInputChange}
            fullWidth
            sx={{ mb: 2 }}
          />

          <Box display="flex" justifyContent="space-between">
            <Button onClick={handleBack}>Atrás</Button>
            <Button variant="contained" color="primary" onClick={handleSaveClick}>
              Guardar
            </Button>
          </Box>
        </StepContent>
      </Step>
    </Stepper>
  );
};

export default ReservationStepper;